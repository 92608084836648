<!--
 * @Author: PC
 * @Date: 2022-04-02 17:59:56
 * @LastEditTime: 2022-04-24 15:50:09
 * @Description: 移动端提示页面
-->
<template>
  <div class="tips_page">
    <div class="inner">
      <div class="tips">此应用无法通过手机端访问，请复制以下链接到电脑端浏览器访问!</div>
      <div class="url">https://dataview.bjstarfish.com/login</div>
      <div>
        <input class="copyInput" type="text" id="copyVal" value="https://dataview.bjstarfish.com/login" />
        <a-button type="primary" @click="copy">复制</a-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },

  methods: {
    copy() {
      let copyVal = document.getElementById("copyVal");
      copyVal.select();
      document.execCommand("copy");
      alert("复制成功");
    },
  },
};
</script>

<style lang="less" scoped>
.tips_page {
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  .inner {
    padding: 24px;
    text-align: center;
  }
  .tips {
    margin-top: 30px;
    font-size: 16px;
    color: #111;
  }
  .url {
    font-size: 16px;
    color: rgb(28, 75, 245);
    margin: 24px 0;
  }
  .copyInput {
    position: absolute;
    top: -1000000px;
    left: -100000px;
    width: 1px;
    height: 1px;
    opacity: 0;
  }
}
</style>
